import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot,} from '@angular/router';
import {EMPTY, of} from 'rxjs';
import {NotificationService} from 'src/app/services/notification.service';
import {GlobalService} from 'src/app/services/global.service';
import {EViewRoutes} from './enum';
import {
  Campaign,
  CampaignService,
  Client,
  ClientService, Employee, EmployeeService,
  Intermediary,
  IntermediaryService,
  MonitoringCampaign,
  MonitoringService,
  Portfolio,
  PortfolioService,
  Product,
  ProductService, PublicationType, PublicationTypeService,
  Story,
  StoryService,
} from 'src/app/api/core';
import {catchError, switchMap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {PermissionService} from 'src/app/services/permission.service';
import {StoredError} from 'src/app/models/error.model';
import {ErrorService} from 'src/app/services/error.service';

export const StoryResolver: ResolveFn<Story> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const storyService = inject(StoryService);
  const notificationService = inject(NotificationService);
  const globalService = inject(GlobalService);
  const translateService = inject(TranslateService);
  return storyService.getStory(Number(route.paramMap.get('id'))).pipe(
    catchError(() => {
      notificationService.handleError(
        translateService.instant('doesNotExistOrNoPermissionErrorStory')
      );
      globalService.navigate(EViewRoutes.stories);
      return EMPTY;
    })
  );
};

export const CampaignResolver: ResolveFn<Campaign> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const campaignService = inject(CampaignService);
  const notificationService = inject(NotificationService);
  const globalService = inject(GlobalService);
  const translateService = inject(TranslateService);
  const permissionService = inject(PermissionService);
  return campaignService.getCampaign(Number(route.paramMap.get('id'))).pipe(
    switchMap((campaign) => {
      if (permissionService.hasPermissionForCampaignOverview(campaign)) {
        return of(campaign);
      } else {
        notificationService.handleError(
          translateService.instant('doesNotExistOrNoPermissionErrorCampaign')
        );
        globalService.navigate(EViewRoutes.home);
        return EMPTY;
      }
    }),
    catchError(() => {
      notificationService.handleError(
        translateService.instant('doesNotExistOrNoPermissionErrorCampaign')
      );
      globalService.navigate(EViewRoutes.campaigns);
      return EMPTY;
    })
  );
};

export const ClientResolver: ResolveFn<Client> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const clientService = inject(ClientService);
  const globalService = inject(GlobalService);
  const notificationService = inject(NotificationService);
  const translateService = inject(TranslateService);
  return clientService.getClient(+route.paramMap.get('id')).pipe(
    catchError(() => {
      notificationService.handleError(
        translateService.instant('doesNotExistOrNoPermissionErrorClient')
      );
      globalService.navigate(EViewRoutes.objectsClients);
      return EMPTY;
    })
  );
};

export const EmployeeResolver: ResolveFn<Employee> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const employeeService = inject(EmployeeService);
  const globalService = inject(GlobalService);
  const notificationService = inject(NotificationService);
  const translateService = inject(TranslateService);
  return employeeService.getEmployee(+route.paramMap.get('id')).pipe(
    catchError(() => {
      notificationService.handleError(
        translateService.instant('doesNotExistOrNoPermissionErrorEmployee')
      );
      globalService.navigate(EViewRoutes.objectsEmployees);
      return EMPTY;
    })
  );
};

export const IntermediaryResolver: ResolveFn<Intermediary> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const intermediaryService = inject(IntermediaryService);
  const globalService = inject(GlobalService);
  const notificationService = inject(NotificationService);
  const translateService = inject(TranslateService);
  return intermediaryService.getIntermediary(+route.paramMap.get('id')).pipe(
    catchError(() => {
      notificationService.handleError(
        translateService.instant('doesNotExistOrNoPermissionErrorIntermediary')
      );
      globalService.navigate(EViewRoutes.objectsIntermediaries);
      return EMPTY;
    })
  );
};

export const PortfolioResolver: ResolveFn<Portfolio> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const portfolioService = inject(PortfolioService);
  const globalService = inject(GlobalService);
  const notificationService = inject(NotificationService);
  const translateService = inject(TranslateService);
  return portfolioService.getPortfolio(+route.paramMap.get('id')).pipe(
    catchError(() => {
      notificationService.handleError(
        translateService.instant('doesNotExistOrNoPermissionErrorPortfolio')
      );
      globalService.navigate(EViewRoutes.objectsPortfolios);
      return EMPTY;
    })
  );
};

export const ProductResolver: ResolveFn<Product> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const productService = inject(ProductService);
  const globalService = inject(GlobalService);
  const notificationService = inject(NotificationService);
  const translateService = inject(TranslateService);
  return productService.getProduct(parseInt(route.paramMap.get('id'), 10)).pipe(
    catchError(() => {
      notificationService.handleError(
        translateService.instant('doesNotExistOrNoPermissionErrorProduct')
      );
      globalService.navigate(EViewRoutes.objectsProducts);
      return EMPTY;
    })
  );
};

export const PublicationTypeResolver: ResolveFn<PublicationType> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const pubTypeService = inject(PublicationTypeService);
  const globalService = inject(GlobalService);
  const notificationService = inject(NotificationService);
  const translateService = inject(TranslateService);
  return pubTypeService.getPublicationType(parseInt(route.paramMap.get('id'), 10)).pipe(
    catchError(() => {
      notificationService.handleError(
        translateService.instant('doesNotExistOrNoPermissionErrorPublicationType')
      );
      globalService.navigate(EViewRoutes.publicationTypeList);
      return EMPTY;
    })
  );
};

export const ErrorResolver: ResolveFn<StoredError> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const errorService = inject(ErrorService);
  return errorService.getErrorByTimestamp(Number(route.paramMap.get('id')));
};

export const MonitoringCampaignResolver: ResolveFn<MonitoringCampaign> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const monitoringService = inject(MonitoringService);
  const notificationService = inject(NotificationService);
  const globalService = inject(GlobalService);
  const translateService = inject(TranslateService);
  return monitoringService
    .getCampaignStats(Number(route.paramMap.get('id')), true)
    .pipe(
      catchError(() => {
        notificationService.handleError(
          translateService.instant(
            'doesNotExistOrNoPermissionErrorMonitoringCampaign'
          )
        );
        globalService.navigate(EViewRoutes.monitoringCampaign);
        return EMPTY;
      })
    );
};
